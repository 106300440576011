export default defineNuxtRouteMiddleware(async (to, from) => {
  const { logPageView } = useAnalytics();

  const { shouldRequestAuthentication, authenticationForm } = storeToRefs(
    useAuthStore()
  );

  authenticationForm.value = 'reset-password';
  shouldRequestAuthentication.value = true;

  const { email } = from.query;
  const { token } = from.params;

  logPageView('Reset password');

  return navigateTo({ name: 'index', query: { email, token }, replace: true });
});
